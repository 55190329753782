import React from 'react'
import {
  CalendarBlank,
} from 'phosphor-react'
import { BasicLayout } from '../layouts/basic'
import { useAuthors } from '../hooks/useAuthors'
import { SEOMeta } from '../components/SEOMeta'
import { Avatar } from '../components/blog/Avatar'

const Index = ({ location }) => {
  const authors = useAuthors()

  return (
    <BasicLayout>
      <SEOMeta title="Qualdesk: Teamwork 2.0" path={location.pathname} />
      <article className="mx-auto p-6 max-w-3xl lg:max-w-4xl">
        <header className="md:to-teal-400 md:bg-gradient-to-br md:from-blue-700 md:p-1 md:shadow-solid lg:mt-6 md:rounded-3xl">
          <div className="md:p-8 md:py-12 md:bg-white md:rounded-[20px] lg:px-10 lg:py-16 lg:pb-20">
            <div className="mx-auto max-w-xl lg:max-w-2xl">
              <h1 className="text-text-primary-light text-3xl font-extrabold tracking-tight leading-tight sm:text-4xl lg:text-5xl">
                The next big thing for Qualdesk
              </h1>
              <div className="flex items-center mt-6 mb-8 text-text-secondary-light text-sm space-x-1">
                <CalendarBlank className="flex-shrink-0" />
                <time>21 Jan 2022</time>
              </div>
              <ul className="mt-6 space-y-4 sm:flex sm:space-x-10 sm:space-y-0 md:pt-8 md:border-t md:border-border-weak-light">
                {['peter-parkes', 'lukasz-sagol']
                .map((id) => authors[id])
                .map((author) => (
                  <li key={author.name} className="flex items-center space-x-4">
                    <Avatar author={author} />
                    <div>
                      <div className="text-text-primary-light text-sm">
                        {author.name}
                      </div>
                      <div className="text-text-secondary-light text-xs">
                        {author.title}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </header>
        <div className="mt-10 mx-auto max-w-xl text-text-primary-light text-sm md:mt-16 lg:max-w-2xl">
          <div>
            <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl font-normal">We started Qualdesk with a simple belief that we could build a better collaboration experience for product development teams based on deep features and connections designed for product, design and engineering people like you.</p>
            <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl font-normal">For the past year, you’ve been a critical part of helping us along this journey. You’ve provided us detailed feedback, suggested some awesome new features and fundamentally shaped the product we’ve shipped.</p>
            <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl font-normal"><strong className="font-medium">As a team, we made the decision to join Miro: a company that shares our vision, and has the means and market position to deliver on that vision at awesome scale. As a result, we plan to sunset the Qualdesk product in 30 days, on February 18, 2022.</strong></p>
            <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl font-normal">As you know, we offer a CSV export which will allow you to capture and save any work you currently have on your boards. And, as always, we are here to help you through this transition. Please feel free to reach out to me with any questions or if you need any help exporting your data.</p>
            <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl font-normal">We’re excited about the opportunity ahead of us and while we can’t give you much detail at this stage, we look forward to sharing more with you in the near future.</p>
            <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl font-normal">Finally, I’d like to thank you for helping us along the way.</p>
            <p className="last:mb-0 first:mt-0 my-oneline text-base sm:text-lg lg:text-xl font-normal">Stay tuned!</p>
          </div>
        </div>
      </article>
    </BasicLayout>
  )
}

export default Index
