import React from 'react'

export const Avatar = ({ author }) => {
  return (
    <div className="shadow-solid-xs inline-block -ml-1 first:ml-0 w-10 h-10 rounded-full">
      <img
        className="rounded-full"
        src={author.avatar.publicURL}
        alt={author.name}
        title={author.name}
      />
    </div>
  )
}
