/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { useStaticQuery, graphql } from 'gatsby'
import { AuthorsYaml, File, UseAuthorsQuery, Maybe } from '../../graphql-types'

/*---------------------------------------------------------------------------*/

type Author = Pick<AuthorsYaml, 'id' | 'name' | 'title'> & {
  avatar?: Maybe<Pick<File, 'publicURL'>>
}

export const useAuthors = () => {
  const { allAuthorsYaml } = useStaticQuery<UseAuthorsQuery>(
    graphql`
      query UseAuthors {
        allAuthorsYaml {
          nodes {
            id
            name
            title
            avatar {
              publicURL
            }
          }
        }
      }
    `
  )
  return allAuthorsYaml.nodes.reduce<Record<string, Author>>(
    (acc, author) => ({ [author.id]: author, ...acc }),
    {}
  )
}
